.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.nonpadding {
  padding: 0;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.vis-hidden {
  opacity: 0;
}

.react-phone-number-input__icon {
  border: 0;
}

@media only screen and (max-width: 770px) {
  input {
    text-align: center;
  }
}

.react-tel-input input {
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  width: 210px !important;
  border-radius: 0px !important;
}

.flag-dropdown {
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
  background-color: white !important;
  border-radius: 0px !important;
}

.form-control:focus {
  box-shadow: none !important;
}

@media only screen and (max-width: 1444px) {
  .img-fluid {
    width: 80%;
  }
}

.img-parallax {
  position: fixed;
  top: 0;
  right: 10px;
  max-width: 800px;
  height: 600px;
  width: 100%;
}

.first-image {
  width: 600px;
}

@media only screen and (max-width: 1550px) {
  .img-parallax {
    position: fixed;
    top: -20px;
    right: 10px;
    max-width: 700px;
    height: 600px;
    width: 100%;
  }

  .first-image {
    width: 500px;
  }
}

@media only screen and (max-width: 1200px) {
  .img-parallax {
    position: fixed;
    top: -20px;
    right: 10px;
    max-width: 600px;
    height: 400px;
    width: 100%;
  }

  .first-image {
    width: 400px;
  }
}

@media only screen and (max-width: 1000px) {
  .img-parallax {
    position: fixed;
    top: -20px;
    right: 10px;
    max-width: 470px;
    height: 350px;
    width: 100%;
  }

  .first-image {
    width: 400px;
  }
}

@media only screen and (max-width: 1000px) {
  .img-parallax {
    position: fixed;
    top: -20px;
    right: 10px;
    max-width: 450px;
    height: 250px;
    width: 100%;
  }

  .first-image {
    width: 300px;
  }
}
.scrollable-container {
  z-index: 1000;
}

@media only screen and (max-width: 768px) {
  .img-parallax {
    position: fixed;
    top: -100px;
    right: 10px;
    max-width: 350px;
    height: 300px;
    width: unset;
  }

  #menu- > div:last-child {
    max-width: 90vw;
    min-width: unset !important;
  }

  #menu- ul {
    overflow-x: auto;
  }
  #menu- ul li {
    display: flex;
  }
}

.navbar {
  z-index: 2;
}

.fb_dialog_advanced {
  bottom: 50pt !important;
}

.fb_customer_chat_bounce_in_v2 {
  bottom: 88pt !important;
}

#formControlsTextB {
  width: 210px;
  margin-left: auto;
}

#daterangepicker {
  right: 0 !important;
  left: unset !important;
}
.daterangepicker:before {
  right: 0;
}

.daterangepicker:after {
  right: 0;
}

.ant-modal-mask {
  z-index: 1400 !important;
}

.ant-modal-wrap {
  z-index: 1401 !important;
}

.react-tel-input input {
  border-bottom: none !important;
}

.react-tel-input .form-control {
  height: 100%;
}

.react-tel-input .react-tel-input {
  top: 2px;
}

.ql-container {
  height: 210px !important;
}

.ql-editor {
  height: 170px !important;
  overflow: auto;
}

.tab-default {
  font-size: 14px !important;
  color: #000000 !important;
  text-transform: capitalize !important;
  outline: none !important;
}

.lazy-inline {
  display: inline-block;
}

.react-tel-input input {
  text-align: left;
  padding-left: 40px;
  padding-bottom: 14px;
  margin-top: -3px;
}

.collapse-menu-bar {
  max-width: 70px;
}

.collapse-menu-bar-app-bar {
  margin-left: 70px !important;
}

.dashboard-search-bar {
  padding: 8px;
  background-color: #ffffff;
  align-items: center;
}

@media (min-width: 600px) {
  .collapse-menu-bar-app-bar {
    width: calc(100% - 70px) !important;
  }

  .dashboard-search-bar {
    position: fixed;
    top: 64px;
    left: 200px;
    right: 0;
    z-index: 10;
    width: calc(100vw - 200px) !important;
  }

  .dashboard-search-bar--collapsed {
    left: 70px;
    width: calc(100vw - 70px) !important;
  }
}
