.c\:black {
  color: black !important;
}

.c\:white {
  color: white !important;
}

.c\:blue {
  color: blue !important;
}

// Using: c:#7a82ff
.c\:\#7a82ff {
  color: #7a82ff !important;
}

.c\:\#283583 {
  color: #283583 !important;
}

.c\:\#5d8ffc {
  color: #5d8ffc !important;
}

.c\:\#929699 {
  color: #929699 !important;
}

.c\:\#1a173b {
  color: #1a173b !important;
}

/*** hover ***/
.hover\:c\:\#ffffff:hover {
  color: #ffffff !important;
}
