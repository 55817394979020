body {
  /*** padding ***/
  /*** padding-all ***/
  .p-0 {
    padding: 0 !important;
  }

  .p-2 {
    padding: 2px !important;
  }

  .p-8 {
    padding: 8px !important;
  }

  .p-10 {
    padding: 10px !important;
  }

  .p-12 {
    padding: 12px !important;
  }

  .p-15 {
    padding: 15px !important;
  }

  .p-16 {
    padding: 16px !important;
  }

  .p-20 {
    padding: 20px !important;
  }

  .p-24 {
    padding: 24px !important;
  }

  /*** padding-horizontal ***/
  .px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .px-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .px-28 {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }

  .px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .px-32 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  /*** padding-vertical ***/
  .py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .py-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  /*** padding-top ***/
  .pt-0 {
    padding-top: 0px !important;
  }

  .pt-1 {
    padding-top: 1px !important;
  }

  .pt-2 {
    padding-top: 2px !important;
  }

  .pt-3 {
    padding-top: 3px !important;
  }

  .pt-4 {
    padding-top: 4px !important;
  }

  .pt-5 {
    padding-top: 5px !important;
  }

  .pt-6 {
    padding-top: 6px !important;
  }

  .pt-7 {
    padding-top: 7px !important;
  }

  .pt-8 {
    padding-top: 8px !important;
  }

  .pt-9 {
    padding-top: 9px !important;
  }

  .pt-10 {
    padding-top: 10px !important;
  }

  .pt-11 {
    padding-top: 11px !important;
  }

  .pt-12 {
    padding-top: 12px !important;
  }

  .pt-13 {
    padding-top: 13px !important;
  }

  .pt-14 {
    padding-top: 14px !important;
  }

  .pt-15 {
    padding-top: 15px !important;
  }

  .pt-16 {
    padding-top: 16px !important;
  }

  .pt-17 {
    padding-top: 17px !important;
  }

  .pt-18 {
    padding-top: 18px !important;
  }

  .pt-19 {
    padding-top: 19px !important;
  }

  .pt-20 {
    padding-top: 20px !important;
  }

  .pt-22 {
    padding-top: 22px !important;
  }

  .pt-30 {
    padding-top: 30px !important;
  }

  /*** padding-bottom ***/
  .pb-0 {
    padding-bottom: 0px !important;
  }

  .pb-1 {
    padding-bottom: 1px !important;
  }

  .pb-2 {
    padding-bottom: 2px !important;
  }

  .pb-3 {
    padding-bottom: 3px !important;
  }

  .pb-4 {
    padding-bottom: 4px !important;
  }

  .pb-5 {
    padding-bottom: 5px !important;
  }

  .pb-6 {
    padding-bottom: 6px !important;
  }

  .pb-7 {
    padding-bottom: 7px !important;
  }

  .pb-8 {
    padding-bottom: 8px !important;
  }

  .pb-9 {
    padding-bottom: 9px !important;
  }

  .pb-10 {
    padding-bottom: 10px !important;
  }

  .pb-11 {
    padding-bottom: 11px !important;
  }

  .pb-12 {
    padding-bottom: 12px !important;
  }

  .pb-13 {
    padding-bottom: 13px !important;
  }

  .pb-14 {
    padding-bottom: 14px !important;
  }

  .pb-15 {
    padding-bottom: 15px !important;
  }

  .pb-16 {
    padding-bottom: 16px !important;
  }

  .pb-17 {
    padding-bottom: 17px !important;
  }

  .pb-18 {
    padding-bottom: 18px !important;
  }

  .pb-19 {
    padding-bottom: 19px !important;
  }

  .pb-20 {
    padding-bottom: 20px !important;
  }

  /*** padding-left ***/
  .pl-0 {
    padding-left: 0px !important;
  }

  .pl-1 {
    padding-left: 1px !important;
  }

  .pl-2 {
    padding-left: 2px !important;
  }

  .pl-3 {
    padding-left: 3px !important;
  }

  .pl-4 {
    padding-left: 4px !important;
  }

  .pl-5 {
    padding-left: 5px !important;
  }

  .pl-6 {
    padding-left: 6px !important;
  }

  .pl-7 {
    padding-left: 7px !important;
  }

  .pl-8 {
    padding-left: 8px !important;
  }

  .pl-9 {
    padding-left: 9px !important;
  }

  .pl-10 {
    padding-left: 10px !important;
  }

  .pl-11 {
    padding-left: 11px !important;
  }

  .pl-12 {
    padding-left: 12px !important;
  }

  .pl-13 {
    padding-left: 13px !important;
  }

  .pl-14 {
    padding-left: 14px !important;
  }

  .pl-15 {
    padding-left: 15px !important;
  }

  .pl-16 {
    padding-left: 16px !important;
  }

  .pl-17 {
    padding-left: 17px !important;
  }

  .pl-18 {
    padding-left: 18px !important;
  }

  .pl-19 {
    padding-left: 19px !important;
  }

  .pl-20 {
    padding-left: 20px !important;
  }

  .pl-30 {
    padding-left: 30px !important;
  }

  .pl-40 {
    padding-left: 40px !important;
  }

  /*** padding-right ***/
  .pr-0 {
    padding-right: 0px !important;
  }

  .pr-1 {
    padding-right: 1px !important;
  }

  .pr-2 {
    padding-right: 2px !important;
  }

  .pr-3 {
    padding-right: 3px !important;
  }

  .pr-4 {
    padding-right: 4px !important;
  }

  .pr-5 {
    padding-right: 5px !important;
  }

  .pr-6 {
    padding-right: 6px !important;
  }

  .pr-7 {
    padding-right: 7px !important;
  }

  .pr-8 {
    padding-right: 8px !important;
  }

  .pr-9 {
    padding-right: 9px !important;
  }

  .pr-10 {
    padding-right: 10px !important;
  }

  .pr-11 {
    padding-right: 11px !important;
  }

  .pr-12 {
    padding-right: 12px !important;
  }

  .pr-13 {
    padding-right: 13px !important;
  }

  .pr-14 {
    padding-right: 14px !important;
  }

  .pr-15 {
    padding-right: 15px !important;
  }

  .pr-16 {
    padding-right: 16px !important;
  }

  .pr-17 {
    padding-right: 17px !important;
  }

  .pr-18 {
    padding-right: 18px !important;
  }

  .pr-19 {
    padding-right: 19px !important;
  }

  .pr-20 {
    padding-right: 20px !important;
  }

  .pr-22 {
    padding-right: 22px !important;
  }

  .pr-70 {
    padding-right: 70px !important;
  }

  /*** margin ***/
  /*** margin-all ***/
  .m-auto {
    margin: auto !important;
  }

  .m-0 {
    margin: 0 !important;
  }

  .m-15 {
    margin: 15px !important;
  }

  .mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  /*** margin-top ***/
  .-mt-16 {
    margin-top: -16px !important;
  }

  .-mt-5 {
    margin-top: -5px !important;
  }

  .mt-0 {
    margin-top: 0px !important;
  }

  .mt-1 {
    margin-top: 1px !important;
  }

  .mt-2 {
    margin-top: 2px !important;
  }

  .mt-3 {
    margin-top: 3px !important;
  }

  .mt-4 {
    margin-top: 4px !important;
  }

  .mt-5 {
    margin-top: 5px !important;
  }

  .mt-6 {
    margin-top: 6px !important;
  }

  .mt-7 {
    margin-top: 7px !important;
  }

  .mt-8 {
    margin-top: 8px !important;
  }

  .mt-9 {
    margin-top: 9px !important;
  }

  .mt-10 {
    margin-top: 10px !important;
  }

  .mt-11 {
    margin-top: 11px !important;
  }

  .mt-12 {
    margin-top: 12px !important;
  }

  .mt-13 {
    margin-top: 13px !important;
  }

  .mt-14 {
    margin-top: 14px !important;
  }

  .mt-15 {
    margin-top: 15px !important;
  }

  .mt-16 {
    margin-top: 16px !important;
  }

  .mt-17 {
    margin-top: 17px !important;
  }

  .mt-18 {
    margin-top: 18px !important;
  }

  .mt-19 {
    margin-top: 19px !important;
  }

  .mt-20 {
    margin-top: 20px !important;
  }

  .mt-50 {
    margin-top: 50px !important;
  }

  .mt-60 {
    margin-top: 60px !important;
  }

  /*** margin-bottom ***/
  .-mb-10 {
    margin-bottom: -10px !important;
  }

  .-mb-15 {
    margin-bottom: -15px !important;
  }

  .-mb-20 {
    margin-bottom: -20px !important;
  }

  .mb-0 {
    margin-bottom: 0px !important;
  }

  .mb-1 {
    margin-bottom: 1px !important;
  }

  .mb-2 {
    margin-bottom: 2px !important;
  }

  .mb-3 {
    margin-bottom: 3px !important;
  }

  .mb-4 {
    margin-bottom: 4px !important;
  }

  .mb-5 {
    margin-bottom: 5px !important;
  }

  .mb-5 {
    margin-bottom: 5px !important;
  }

  .mb-6 {
    margin-bottom: 6px !important;
  }

  .mb-7 {
    margin-bottom: 7px !important;
  }

  .mb-8 {
    margin-bottom: 8px !important;
  }

  .mb-9 {
    margin-bottom: 9px !important;
  }

  .mb-10 {
    margin-bottom: 10px !important;
  }

  .mb-11 {
    margin-bottom: 11px !important;
  }

  .mb-12 {
    margin-bottom: 12px !important;
  }

  .mb-13 {
    margin-bottom: 13px !important;
  }

  .mb-14 {
    margin-bottom: 14px !important;
  }

  .mb-15 {
    margin-bottom: 15px !important;
  }

  .mb-16 {
    margin-bottom: 16px !important;
  }

  .mb-17 {
    margin-bottom: 17px !important;
  }

  .mb-18 {
    margin-bottom: 18px !important;
  }

  .mb-19 {
    margin-bottom: 19px !important;
  }

  .mb-20 {
    margin-bottom: 20px !important;
  }

  .mb-25 {
    margin-bottom: 25px !important;
  }

  .mb-50 {
    margin-bottom: 50px !important;
  }

  .mb-60 {
    margin-bottom: 60px !important;
  }

  /*** margin-left ***/
  .ml-0 {
    margin-left: 0px !important;
  }

  .ml-1 {
    margin-left: 1px !important;
  }

  .ml-2 {
    margin-left: 2px !important;
  }

  .ml-3 {
    margin-left: 3px !important;
  }

  .ml-4 {
    margin-left: 4px !important;
  }

  .ml-5 {
    margin-left: 5px !important;
  }

  .ml-6 {
    margin-left: 6px !important;
  }

  .ml-7 {
    margin-left: 7px !important;
  }

  .ml-8 {
    margin-left: 8px !important;
  }

  .ml-9 {
    margin-left: 9px !important;
  }

  .ml-10 {
    margin-left: 10px !important;
  }

  .ml-11 {
    margin-left: 11px !important;
  }

  .ml-12 {
    margin-left: 12px !important;
  }

  .ml-13 {
    margin-left: 13px !important;
  }

  .ml-14 {
    margin-left: 14px !important;
  }

  .ml-15 {
    margin-left: 15px !important;
  }

  .ml-16 {
    margin-left: 16px !important;
  }

  .ml-17 {
    margin-left: 17px !important;
  }

  .ml-18 {
    margin-left: 18px !important;
  }

  .ml-19 {
    margin-left: 19px !important;
  }

  .ml-20 {
    margin-left: 20px !important;
  }

  .ml-30 {
    margin-left: 30px !important;
  }

  /*** margin-right ***/
  .mr-0 {
    margin-right: 0px !important;
  }

  .mr-1 {
    margin-right: 1px !important;
  }

  .mr-2 {
    margin-right: 2px !important;
  }

  .mr-3 {
    margin-right: 3px !important;
  }

  .mr-4 {
    margin-right: 4px !important;
  }

  .mr-5 {
    margin-right: 5px !important;
  }

  .mr-6 {
    margin-right: 6px !important;
  }

  .mr-7 {
    margin-right: 7px !important;
  }

  .mr-8 {
    margin-right: 8px !important;
  }

  .mr-9 {
    margin-right: 9px !important;
  }

  .mr-10 {
    margin-right: 10px !important;
  }

  .mr-11 {
    margin-right: 11px !important;
  }

  .mr-12 {
    margin-right: 12px !important;
  }

  .mr-13 {
    margin-right: 13px !important;
  }

  .mr-14 {
    margin-right: 14px !important;
  }

  .mr-15 {
    margin-right: 15px !important;
  }

  .mr-16 {
    margin-right: 16px !important;
  }

  .mr-17 {
    margin-right: 17px !important;
  }

  .mr-18 {
    margin-right: 18px !important;
  }

  .mr-19 {
    margin-right: 19px !important;
  }

  .mr-20 {
    margin-right: 20px !important;
  }

  .mr-30 {
    margin-right: 30px !important;
  }

  @media (min-width: 1024px) {
    .lg\:-mt-3 {
      margin-bottom: 3px !important;
    }

    .lg\:-mt-5 {
      margin-top: -5px !important;
    }

    .lg\:mr-20 {
      margin-right: 20px !important;
    }

    .lg\:ml-20 {
      margin-left: 20px !important;
    }

    .lg\:mb-15 {
      margin-bottom: 15px !important;
    }

    .lg\:mb-17 {
      margin-bottom: 17px !important;
    }

    .lg\:p-12 {
      padding: 12px !important;
    }

     .xl\:pr-70 {
      padding-right: 70px !important;
    }

    .lg\:pl-0 {
      padding-left :0px !important;
    }

    .lg\:pt-42 {
      padding-top :42px !important;
    }

    .lg\:px-70 {
      padding-right :70px !important;
      padding-left :70px !important;
    }

    .lg\:pb-0 {
      padding-bottom :0px !important;
    }
  }

  @media (min-width: 1280px) {
    .xl\:py-42 {
      padding-right :42px !important;
      padding-left :42px !important;
    }
  }
}
