/*** overflow ***/
.overflow-hidden {
  overflow: hidden !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

/*** display ***/
.block {
  display: block !important;
}

.grid {
  display: grid !important;
}

.inline-block {
  display: inline-block !important;
}

.inline {
  display: inline !important;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

.grid {
  display: grid !important;
}

.hidden {
  display: none !important;
}

.contents {
  display: contents !important;
}

/*** float ***/
.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.float-none {
  float: none !important;
}

.clearfix {
  content: '' !important;
  display: table !important;
  clear: both !important;
}

@media (min-width: 640px) {
  .sm\:flex {
    display: flex !important;
  }
}

@media (min-width: 768px) {
  .md\:flex {
    display: flex !important;
  }
}

@media (min-width: 1024px) {
  .lg\:flex {
    display: flex !important;
  }
}

@media (min-width: 1280px) {
  .xl\:flex {
    display: flex !important;
  }
}
