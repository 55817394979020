.bg-none {
  background: none !important;
}

.bg-c\:white {
  background-color: white !important;
}

.bg-c\:red {
  background-color: red !important;
}

// Using: bg-c:#171717
.bg-c\:\#171717 {
  background-color: #171717 !important;
}

.bg-c\:\#283583 {
  background-color: #283583 !important;
}


