@mixin scrollCustom($width, $height, $background, $borderRadius) {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: $width;
    height: $height;
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    background: $background;
    border-radius: $borderRadius;
  }
}

div,
.scrollbar-10\#e2e2e2 {
  @include scrollCustom(10px, 10px, #e2e2e2, 0);
}
