.fixed {
  position: fixed !important;
}

.sticky {
  position: sticky !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.unset {
  position: unset !important;
}

.inset-y-0 {
  top: 0 !important;
  bottom: 0 !important;
}

.inset-x-0 {
  right: 0 !important;
  left: 0 !important;
}

.top-0 {
  top: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.left-0 {
  left: 0 !important;
}

.z-0 {
  z-index: 0 !important;
}

@media only screen and (min-width: 1024px) {
  .lg\:absolute {
    position: absolute !important;
  }

  .lg\:left-0 {
    left: 0 !important;
  }

  .lg\:bottom-0 {
    bottom: 0 !important;
  }

}
