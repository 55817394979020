.w-auto {
  width: auto !important;
}

.w-1\/2 {
  width: 50% !important;
}

.w-1\/3 {
  width: 33.333333% !important;
}

.w-2\/3 {
  width: 66.666667% !important;
}

.w-1\/4 {
  width: 25% !important;
}

.w-2\/4 {
  width: 50% !important;
}

.w-3\/4 {
  width: 75% !important;
}

.w-1\/5 {
  width: 20% !important;
}

.w-2\/5 {
  width: 40% !important;
}

.w-3\/5 {
  width: 60% !important;
}

.w-4\/5 {
  width: 80% !important;
}

.w-1\/6 {
  width: 16.666667% !important;
}

.w-2\/6 {
  width: 33.333333% !important;
}

.w-3\/6 {
  width: 50% !important;
}

.w-4\/6 {
  width: 66.666667% !important;
}

.w-5\/6 {
  width: 83.333333% !important;
}

.w-1\/12 {
  width: 8.333333% !important;
}

.w-2\/12 {
  width: 16.666667% !important;
}

.w-3\/12 {
  width: 25% !important;
}

.w-4\/12 {
  width: 33.333333% !important;
}

.w-5\/12 {
  width: 41.666667% !important;
}

.w-6\/12 {
  width: 50% !important;
}

.w-7\/12 {
  width: 58.333333% !important;
}

.w-8\/12 {
  width: 66.666667% !important;
}

.w-9\/12 {
  width: 75% !important;
}

.w-10\/12 {
  width: 83.333333% !important;
}

.w-11\/12 {
  width: 91.666667% !important;
}

.w-full {
  width: 100% !important;
}

.w-screen {
  width: 100vw !important;
}

.w-35-p {
  width : 35% !important;
}

.w-47-p {
  width : 47% !important;
}

.w-65-p {
  width : 65% !important;
}

.w-96-p {
  width : 96% !important;
}

.w-1 {
  width: 1px !important;
}

.w-13 {
  width: 13px !important;
}

.w-14 {
  width: 14px !important;
}

.w-18 {
  width: 18px !important;
}

.w-30 {
  width: 30px !important;
}

.w-96 {
  width: 96px !important;
}

.w-150 {
  width: 150px !important;
}

.w-200 {
  width: 200px !important;
}

.w-300 {
  width: 300px !important;
}

.w-360 {
  width: 360px !important;
}

.w-700 {
  width: 700px !important;
}

/*** height ***/
.h-0 {
  height: 0px !important;
}

.h-1 {
  height: 1px !important;
}

.h-13 {
  height: 13px !important;
}

.h-14 {
  height: 14px !important;
}

.h-18 {
  height: 18px !important;
}

.h-30 {
  height: 30px !important;
}

.h-36 {
  height: 36px !important;
}

.h-45 {
  height: 45px !important;
}

.h-auto {
  height: auto !important;
}

.h-full {
  height: 100% !important;
}

.h-screen {
  height: 100vh !important;
}

.min-h-auto {
  min-height: auto !important;
}

.min-h-full {
  min-height: 100% !important;
}

.min-h-screen {
  min-height: 100vh !important;
}

.min-h-0 {
  min-height: 0 !important;
}

.min-h-34 {
  min-height: 34px !important;
}

.min-h-50 {
  min-height: 50px !important;
}

.min-h-335 {
  min-height: 335px !important;
}

.max-h-full {
  max-height: 100% !important;
}

.max-h-600 {
  max-height: 600px !important;
}

.max-h-screen {
  max-height: 100vh !important;
}

.min-w-750 {
  min-width: 750px !important;
}

.max-w-120 {
  max-width: 120px !important;
}

.max-w-150 {
  max-width: 150px !important;
}

.max-w-450 {
  max-width: 450px !important;
}

.max-w-1050 {
  max-width: 1050px !important;
}

.max-w-calc-48 {
  max-width: calc(100% - 48px) !important;
}

.max-w-full {
  max-width: 100% !important;
}

@media (min-width: 640px) {
  .sm\:w-auto {
    width: auto !important;
  }

  .sm\:w-1 {
    width: 1px !important;
  }

  .sm\:w-1\/2 {
    width: 50% !important;
  }

  .sm\:w-1\/3 {
    width: 33.333333% !important;
  }

  .sm\:w-2\/3 {
    width: 66.666667% !important;
  }

  .sm\:w-1\/4 {
    width: 25% !important;
  }

  .sm\:w-2\/4 {
    width: 50% !important;
  }

  .sm\:w-3\/4 {
    width: 75% !important;
  }

  .sm\:w-1\/5 {
    width: 20% !important;
  }

  .sm\:w-2\/5 {
    width: 40% !important;
  }

  .sm\:w-3\/5 {
    width: 60% !important;
  }

  .sm\:w-4\/5 {
    width: 80% !important;
  }

  .sm\:w-1\/6 {
    width: 16.666667% !important;
  }

  .sm\:w-2\/6 {
    width: 33.333333% !important;
  }

  .sm\:w-3\/6 {
    width: 50% !important;
  }

  .sm\:w-4\/6 {
    width: 66.666667% !important;
  }

  .sm\:w-5\/6 {
    width: 83.333333% !important;
  }

  .sm\:w-1\/12 {
    width: 8.333333% !important;
  }

  .sm\:w-2\/12 {
    width: 16.666667% !important;
  }

  .sm\:w-3\/12 {
    width: 25% !important;
  }

  .sm\:w-4\/12 {
    width: 33.333333% !important;
  }

  .sm\:w-5\/12 {
    width: 41.666667% !important;
  }

  .sm\:w-6\/12 {
    width: 50% !important;
  }

  .sm\:w-7\/12 {
    width: 58.333333% !important;
  }

  .sm\:w-8\/12 {
    width: 66.666667% !important;
  }

  .sm\:w-9\/12 {
    width: 75% !important;
  }

  .sm\:w-10\/12 {
    width: 83.333333% !important;
  }

  .sm\:w-11\/12 {
    width: 91.666667% !important;
  }

  .sm\:w-full {
    width: 100% !important;
  }

  .sm\:w-screen {
    width: 100vw !important;
  }

  .sm\:h-full {
    height: 100% !important;
  }

  .sm\:h-screen {
    height: 100vh !important;
  }
}

@media (min-width: 768px) {
  .md\:w-auto {
    width: auto !important;
  }

  .md\:w-1 {
    width: 1px !important;
  }

  .md\:w-1\/2 {
    width: 50% !important;
  }

  .md\:w-1\/3 {
    width: 33.333333% !important;
  }

  .md\:w-2\/3 {
    width: 66.666667% !important;
  }

  .md\:w-1\/4 {
    width: 25% !important;
  }

  .md\:w-2\/4 {
    width: 50% !important;
  }

  .md\:w-3\/4 {
    width: 75% !important;
  }

  .md\:w-1\/5 {
    width: 20% !important;
  }

  .md\:w-2\/5 {
    width: 40% !important;
  }

  .md\:w-3\/5 {
    width: 60% !important;
  }

  .md\:w-4\/5 {
    width: 80% !important;
  }

  .md\:w-1\/6 {
    width: 16.666667% !important;
  }

  .md\:w-2\/6 {
    width: 33.333333% !important;
  }

  .md\:w-3\/6 {
    width: 50% !important;
  }

  .md\:w-4\/6 {
    width: 66.666667% !important;
  }

  .md\:w-5\/6 {
    width: 83.333333% !important;
  }

  .md\:w-1\/12 {
    width: 8.333333% !important;
  }

  .md\:w-2\/12 {
    width: 16.666667% !important;
  }

  .md\:w-3\/12 {
    width: 25% !important;
  }

  .md\:w-4\/12 {
    width: 33.333333% !important;
  }

  .md\:w-5\/12 {
    width: 41.666667% !important;
  }

  .md\:w-6\/12 {
    width: 50% !important;
  }

  .md\:w-7\/12 {
    width: 58.333333% !important;
  }

  .md\:w-8\/12 {
    width: 66.666667% !important;
  }

  .md\:w-9\/12 {
    width: 75% !important;
  }

  .md\:w-10\/12 {
    width: 83.333333% !important;
  }

  .md\:w-11\/12 {
    width: 91.666667% !important;
  }

  .md\:w-full {
    width: 100% !important;
  }

  .md\:w-120 {
    width: 120px !important;
  }

  .md\:w-150 {
    width: 150px !important;
  }

  .md\:w-screen {
    width: 100vw !important;
  }

  .md\:h-full {
    height: 100% !important;
  }

  .md\:h-screen {
    height: 100vh !important;
  }

  .md\:h-18 {
    height: 18px !important;
  }

  .md\:w-18 {
    width: 18px !important;
  }
}

@media (min-width: 1024px) {
  .lg\:w-auto {
    width: auto !important;
  }

  .lg\:w-1 {
    width: 1px !important;
  }

  .lg\:w-1\/2 {
    width: 50% !important;
  }

  .lg\:w-1\/3 {
    width: 33.333333% !important;
  }

  .lg\:w-2\/3 {
    width: 66.666667% !important;
  }

  .lg\:w-1\/4 {
    width: 25% !important;
  }

  .lg\:w-2\/4 {
    width: 50% !important;
  }

  .lg\:w-3\/4 {
    width: 75% !important;
  }

  .lg\:w-1\/5 {
    width: 20% !important;
  }

  .lg\:w-2\/5 {
    width: 40% !important;
  }

  .lg\:w-3\/5 {
    width: 60% !important;
  }

  .lg\:w-4\/5 {
    width: 80% !important;
  }

  .lg\:w-1\/6 {
    width: 16.666667% !important;
  }

  .lg\:w-2\/6 {
    width: 33.333333% !important;
  }

  .lg\:w-3\/6 {
    width: 50% !important;
  }

  .lg\:w-4\/6 {
    width: 66.666667% !important;
  }

  .lg\:w-5\/6 {
    width: 83.333333% !important;
  }

  .lg\:w-1\/12 {
    width: 8.333333% !important;
  }

  .lg\:w-2\/12 {
    width: 16.666667% !important;
  }

  .lg\:w-3\/12 {
    width: 25% !important;
  }

  .lg\:w-4\/12 {
    width: 33.333333% !important;
  }

  .lg\:w-5\/12 {
    width: 41.666667% !important;
  }

  .lg\:w-47-p {
    width: 47% !important;
  }

  .lg\:w-6\/12 {
    width: 50% !important;
  }

  .lg\:w-7\/12 {
    width: 58.333333% !important;
  }

  .lg\:w-8\/12 {
    width: 66.666667% !important;
  }

  .lg\:w-9\/12 {
    width: 75% !important;
  }

  .lg\:w-10\/12 {
    width: 83.333333% !important;
  }

  .lg\:w-11\/12 {
    width: 91.666667% !important;
  }

  .lg\:w-96-p {
    width: 96% !important;
  }

  .lg\:w-full {
    width: 100% !important;
  }

  .lg\:w-screen {
    width: 100vw !important;
  }

  .lg\:w-700 {
    width: 700px !important;
  }

  .lg\:w-900 {
    width: 900px !important;
  }

  .lg\:h-full {
    height: 100% !important;
  }

  .lg\:h-screen {
    height: 100vh !important;
  }

  .lg\:h-80 {
    height: 80px !important;
  }
}

@media (min-width: 1280px) {
  .xl\:w-auto {
    width: auto !important;
  }

  .xl\:w-1 {
    width: 1px !important;
  }

  .xl\:w-1\/2 {
    width: 50% !important;
  }

  .xl\:w-1\/3 {
    width: 33.333333% !important;
  }

  .xl\:w-2\/3 {
    width: 66.666667% !important;
  }

  .xl\:w-1\/4 {
    width: 25% !important;
  }

  .xl\:w-2\/4 {
    width: 50% !important;
  }

  .xl\:w-3\/4 {
    width: 75% !important;
  }

  .xl\:w-1\/5 {
    width: 20% !important;
  }

  .xl\:w-2\/5 {
    width: 40% !important;
  }

  .xl\:w-3\/5 {
    width: 60% !important;
  }

  .xl\:w-4\/5 {
    width: 80% !important;
  }

  .xl\:w-1\/6 {
    width: 16.666667% !important;
  }

  .xl\:w-2\/6 {
    width: 33.333333% !important;
  }

  .xl\:w-3\/6 {
    width: 50% !important;
  }

  .xl\:w-4\/6 {
    width: 66.666667% !important;
  }

  .xl\:w-5\/6 {
    width: 83.333333% !important;
  }

  .xl\:w-1\/12 {
    width: 8.333333% !important;
  }

  .xl\:w-2\/12 {
    width: 16.666667% !important;
  }

  .xl\:w-3\/12 {
    width: 25% !important;
  }

  .xl\:w-4\/12 {
    width: 33.333333% !important;
  }

  .xl\:w-5\/12 {
    width: 41.666667% !important;
  }

  .xl\:w-6\/12 {
    width: 50% !important;
  }

  .xl\:w-7\/12 {
    width: 58.333333% !important;
  }

  .xl\:w-8\/12 {
    width: 66.666667% !important;
  }

  .xl\:w-9\/12 {
    width: 75% !important;
  }

  .xl\:w-10\/12 {
    width: 83.333333% !important;
  }

  .xl\:w-11\/12 {
    width: 91.666667% !important;
  }

  .xl\:w-full {
    width: 100% !important;
  }

  .xl\:w-screen {
    width: 100vw !important;
  }

  .xl\:h-full {
    height: 100% !important;
  }

  .xl\:h-screen {
    height: 100vh !important;
  }
}
