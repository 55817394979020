.box-shadow-none {
  box-shadow: none !important;
}

.box-shadow-1 {
  box-shadow: 0 5px 10px 0 #b9b9b980 !important;
}

.box-shadow-2 {
  box-shadow: 0 5px 10px 0 #d6d6d680 !important;
}
