/*** font-size ***/
.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-50 {
  font-size: 50px !important;
}

/*** font-weight ***/
.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-extrabold {
  font-weight: 800 !important;
}

.font-black {
  font-weight: 900 !important;
}

/*** text-align ***/
.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-justify {
  text-align: justify !important;
}

.text-end {
  text-align: end !important;
}

/*** word-break ***/
.break-words {
  overflow-wrap: break-word !important;
}

.break-normal {
  overflow-wrap: normal !important;
}

.break-all {
  word-break: break-all !important;
}

.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

/*** text-transform ***/
.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.normal-case {
  text-transform: none !important;
}

/*** text-decoration ***/
.underline {
  text-decoration: underline !important;
}

.line-through {
  text-decoration: line-through !important;
}

.no-underline {
  text-decoration: none !important;
}

/*** line height ***/
.line-height-1 {
  line-height: 1.2 !important;
}

.line-height-18 {
  line-height: 18px !important;
}

.line-height-26 {
  line-height: 26px !important;
}

.line-height-29 {
  line-height: 29px !important;
}

.line-height-40 {
  line-height: 40px !important;
}

@media only screen and (min-width: 768px) {
  .md\:fs-24 {
    font-size: 24px !important;
  }

  .md\:fs-16 {
    font-size: 16px !important;
  }

  .md\:text-left {
    text-align: left !important;
  }
}

@media only screen and (min-width: 1024px) {
   .xl\:fs-16 {
    font-size: 16px !important;
  }

  .xl\:fs-20 {
    font-size: 20px !important;
  }

  .xl\:fs-50 {
    font-size: 50px !important;
  }
}
