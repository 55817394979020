/*** border-radius ***/
.rounded-0 {
  border-radius: 0 !important;
}

.rounded-10 {
  border-radius: 10 !important;
}

.rounded-full {
  border-radius: 9999px !important;
}

/*** border-style ***/
.border-none {
  border-style: none !important;
}

.border-solid {
  border: 0 solid !important;
}

/*** border-width ***/
.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-t-1 {
  border-top-width: 1px !important;
}

.border-t-0 {
  border-top-width: 0 !important;
}

.border-t-2 {
  border-top-width: 2px !important;
}

.border-t-4 {
  border-top-width: 4px !important;
}

.border-r-1 {
  border-right-width: 1px !important;
}

.border-r-0 {
  border-right-width: 0 !important;
}

.border-r-2 {
  border-right-width: 2px !important;
}

.border-r-4 {
  border-right-width: 4px !important;
}

.border-r-blue {
  border-right: solid blue !important;
}

.border-b-1 {
  border-bottom-width: 1px !important;
}

.border-b-0 {
  border-bottom-width: 0 !important;
}

.border-b-2 {
  border-bottom-width: 2px !important;
}

.border-b-4 {
  border-bottom-width: 4px !important;
}

.border-l-1 {
  border-left-width: 1px !important;
}

.border-l-0 {
  border-left-width: 0 !important;
}

.border-l-2 {
  border-left-width: 2px !important;
}

.border-l-4 {
  border-left-width: 4px !important;
}

.hover\:border-none:hover {
  border-style: none !important;
}

/*** border-color ***/
.border\:white {
  border-color: white !important;
}

.border\:black {
  border-color: black !important;
}

.border\:\#FF9800 {
  border-color: #FF9800 !important;
}

