/*** Flex ***/
.flex-1 {
  flex: 1 1 0% !important;
}

.flex-auto {
  flex: 1 1 auto !important;
}

.flex-initial {
  flex: 0 1 auto !important;
}

.flex-none {
  flex: none !important;
}

/*** flex-direction ***/
.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-col-reverse {
  flex-direction: column-reverse !important;
}

.flex-row {
  flex-direction: row !important;
}

/*** flex-basis ***/
.flex-basis {
  flex-basis: 0 !important;
}

/*** flex-grow ***/
.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow {
  flex-grow: 1 !important;
}

/*** flex-shrink ***/
.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink {
  flex-shrink: 1 !important;
}

/*** flex-wrap ***/
.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-no-wrap {
  flex-wrap: nowrap !important;
}

.flex-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/*** align ***/
/*** align-items ***/
.items-start {
  align-items: flex-start !important;
}

.items-end {
  align-items: flex-end !important;
}

.items-center {
  align-items: center !important;
}

.items-baseline {
  align-items: baseline !important;
}

.items-stretch {
  align-items: stretch !important;
}

/*** align-content ***/
.content-center {
  align-content: center !important;
}

.content-start {
  align-content: flex-start !important;
}

.content-end {
  align-content: flex-end !important;
}

.content-between {
  align-content: space-between !important;
}

.content-around {
  align-content: space-around !important;
}

.content-evenly {
  align-content: space-evenly !important;
}

/*** align-self ***/
.self-auto {
  align-self: auto !important;
}

.self-start {
  align-self: flex-start !important;
}

.self-end {
  align-self: flex-end !important;
}

.self-center {
  align-self: center !important;
}

.self-stretch {
  align-self: stretch !important;
}

/*** Justify ***/
/*** justify-items ***/
.justify-items-auto {
  justify-items: auto !important;
}

.justify-items-start {
  justify-items: start !important;
}

.justify-items-end {
  justify-items: end !important;
}

.justify-items-center {
  justify-items: center !important;
}

.justify-items-stretch {
  justify-items: stretch !important;
}

/*** justify-content ***/
.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around !important;
}

.justify-evenly {
  justify-content: space-evenly !important;
}

/*** justify-self ***/
.justify-self-auto {
  justify-self: auto !important;
}

.justify-self-start {
  justify-self: start !important;
}

.justify-self-end {
  justify-self: end !important;
}

.justify-self-center {
  justify-self: center !important;
}

.justify-self-stretch {
  justify-self: stretch !important;
}

@media (max-width: 769px) {
  .md\:flex-col {
    flex-direction: column !important;
  }
}

@media (min-width: 1024px) {
  .lg\:flex {
    display: flex !important;
  }
  .lg\:justify-between {
    justify-content: space-between !important;
  }
}
